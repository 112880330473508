import React from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { AuthContext, Authenticated } from './auth'
import Protected from './Protected'

import RegisterPage from './RegisterPage'
import RefreshTokenScreen from './RefreshTokenScreen'



const basename = window.location.href ? '/react-pkce-sample' : undefined
//const basename = window.location.href 


function App() {
  return (
    <div style={{ padding: 20 }}>
      <AuthContext>
        <div>
          <h1>This is iSprint Testing Demo Site - For testing Logout and Login Use Case</h1>
          <a id="home" className="menu-item" href="/">Home</a> &nbsp;&nbsp;
          <a id="register" className="menu-item" href="/register">Register</a> &nbsp;&nbsp;
          <a id="home" className="menu-item" href="/protected">Login Connect</a>&nbsp;&nbsp;
          <a id="refreshtokenscreen" className="menu-item" href="/refreshTokenScreen">Refresh Token Screen</a>


          

        </div>
        <BrowserRouter>
          <Routes>
          <Route path="/" element={<div >This is ADB2C Demo</div>} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/refreshTokenScreen" element={<RefreshTokenScreen />} />



            <Route path="/protected" element={<Authenticated>
              <Protected />
            </Authenticated>} />
          </Routes>
        </BrowserRouter>
      </AuthContext>
    </div>
  );
}

export default App;

import createAuthContext from './lib/createAuthContext'

const clientId = "5014ed57-49c1-4b9e-acc9-a94f5b7e0c36"
const clientSecret = "";//"1eeb36df-dfff-47b8-b0fb-437870fb9c3b"
const provider = "https://nullmicgo.b2clogin.com/nullmicgo.onmicrosoft.com/B2C_1A_MFA_ISprint_signuporsignin/oauth2/v2.0"
const tokenEndpoint = "https://nullmicgo.b2clogin.com/nullmicgo.onmicrosoft.com/B2C_1A_MFA_ISprint_signuporsignin/oauth2/v2.0/token"






export const {AuthContext, Authenticated, useToken} = createAuthContext({
  clientId,
  clientSecret,
  provider
})

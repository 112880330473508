import * as crypto from 'crypto-js';


const base64URLEncode= (words) =>{
  return crypto.enc.Base64.stringify(words)
  .replace(/\+/g, '-')
  .replace(/\//g, '_')
  .replace(/=/g, '');
}

// var verifier = base64URLEncode(crypto.randomBytes(32));
const sha256= (buffer) =>{
  return crypto.SHA256(buffer)
}

// var challenge = base64URLEncode(sha256(verifier));

export {base64URLEncode, sha256}
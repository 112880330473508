import React, { useState } from 'react';

function RegisterPage() {
  const [userPrincipalName, setUserPrincipalName] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [ssoId, setSsoId] = useState('');
  const [mobileNubmer, setMobileNubmer] = useState('');
  const [password, setPassword] = useState('');

  const [email, setEmail] = useState('');
  const [surname, setSurname] = useState('');
  const [givenName, setGivenName] = useState('');






  const generateRandomBufferForUsername = (length) => {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz012345678';
  
    let password = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      password += characters.charAt(randomIndex);
    }

    return password;
  }



  const generateRandomPassword = (length) => {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=';
  
    let password = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      password += characters.charAt(randomIndex);
    }
  

    return password;
  }
  
  const generateRandomUsername = () => {
    const adjectives = ['happy', 'clever', 'brave', 'sunny', 'vibrant', 'playful'];
    const nouns = ['Michelangelo', 'Leonardo', 'AprilONeil', 'Karai', 'Splinter', 'CaseyJones'];
    

    const randomAdjectiveIndex = Math.floor(Math.random() * adjectives.length);
    const randomNounIndex = Math.floor(Math.random() * nouns.length);
  
    const adjective = adjectives[randomAdjectiveIndex];
    const noun = nouns[randomNounIndex];
  
    const username = adjective + '' + noun + generateRandomBufferForUsername(5);
  
    return username;
  }
  
  const generateRandomHKMobileNumber = () => {
    const prefix = '6';
    const length = 7;
    
    let number = prefix;
    for (let i = 0; i < length; i++) {
      number += Math.floor(Math.random() * 10);
    }
    
    return number;
  }
  


  const handleGenerateMobileNumber = (e) => {
    const randomHKMobileNumber = "+852 "+ generateRandomHKMobileNumber();
    console.log(randomHKMobileNumber);
    setMobileNubmer(randomHKMobileNumber)
}


  const handleGeneratePassword = (e) => {
    // Example usage: generate a random password with a length of 12
    const randomPassword = generateRandomPassword(12);
    setPassword(randomPassword)
}


  const handleGenerateUsername = (e) => {
    // Example usage: generate a random password with a length of 12
    const randomUsername = generateRandomUsername();
    setUserPrincipalName(randomUsername);
    setDisplayName(randomUsername);
    setSsoId(randomUsername);


    setEmail(randomUsername + "@gmail.com");
    setSurname(randomUsername +" Lau");
    setGivenName(randomUsername+ " Michael");
    
}


  const handleSubmit = (e) => {
    e.preventDefault();

    // Prepare the form data
    const formData = new URLSearchParams();
    formData.append('userPrincipalName', userPrincipalName);
    formData.append('displayName', displayName);
    formData.append('password', password);
    formData.append('ssoId', ssoId);
    formData.append('mobilePhone', mobileNubmer);
    formData.append('email', email);
    formData.append('surname', surname);
    formData.append('givenName', givenName);


    

    // Send the request to the API
    fetch('https://b2c-register-api-server.michael-testing.com/create-user', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: formData.toString()
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        if(data.data.result==='success'){
          alert('User Created Successfully');
        }else{
          alert(`User Creation Failed: ${data.data.result}`);
        }

    
      })
      .catch(error => {
        // Handle any errors
        console.error(error);
      });
  };

  return (
    <div style={{ padding: 20 }}>
      <h1>This is a Register Form</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="userPrincipalName">User Principal Name:</label>
          <input
            type="text"
            id="userPrincipalName"
            value={userPrincipalName}
            onChange={(e) => setUserPrincipalName(e.target.value)}
          />
          <span> {userPrincipalName}</span>
        </div>
        <div>
          <label htmlFor="displayName">Display Name:</label>
          <input
            type="text"
            id="displayName"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
          />
            <span> {displayName}</span>
        </div>
        <div>
          <label htmlFor="ssoId">ssoId:</label>
          <input
            type="text"
            id="ssoId"
            value={ssoId}
            onChange={(e) => setSsoId(e.target.value)}
          />
            <span> {ssoId}</span>
        </div>
        <div>
          <label htmlFor="mobileNubmer">mobileNubmer:</label>
          <input
            type="text"
            id="mobileNubmer"
            value={mobileNubmer}
            onChange={(e) => setMobileNubmer(e.target.value)}
          />
            <span> {mobileNubmer}</span>
        </div>

        <div>
          <label htmlFor="email">email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
            <span> {email}</span>
        </div>

        <div>
          <label htmlFor="surname">surname:</label>
          <input
            type="text"
            id="surname"
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
          />
            <span> {surname}</span>
        </div>

        <div>
          <label htmlFor="givenName">givenName:</label>
          <input
            type="text"
            id="givenName"
            value={givenName}
            onChange={(e) => setGivenName(e.target.value)}
          />
            <span> {givenName}</span>
        </div>
        <div>
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <span> {password}</span>
        </div>
        <button type="submit">Submit</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <button type="button" onClick={handleGeneratePassword}>Generate Password</button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <button type="button" onClick={handleGenerateUsername}>Generate Username</button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <button type="button" onClick={handleGenerateMobileNumber}>Generate Mobile Number</button>
      </form>
    </div>
  );
}

export default RegisterPage;
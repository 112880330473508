import React, { useState, useEffect } from 'react';
import * as msal from '@azure/msal-browser';

const msalConfig = {
  auth: {
    clientId: "5014ed57-49c1-4b9e-acc9-a94f5b7e0c36",
    authority: "https://nullmicgo.b2clogin.com/nullmicgo.onmicrosoft.com/B2C_1A_MFA_ISprint_signuporsignin",
    redirectUri: "https://adb2c-isprint-demo.michael-testing.com/protected",
  },
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

const RefreshTokenScreen = () => {
  const [accessToken, setAccessToken] = useState('');

  useEffect(() => {
    acquireToken();
  }, []);

  const acquireToken = async () => {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length === 0) {
      // User is not logged in, initiate login
      await msalInstance.loginPopup();
    } else {
      // User is already logged in, acquire token silently
      const silentRequest = {
        scopes: ['openid', 'offline_access', 'https://nullmicgo.onmicrosoft.com/5014ed57-49c1-4b9e-acc9-a94f5b7e0c36/User.all'],
        account: accounts[0],
      };

      const response = await msalInstance.acquireTokenSilent(silentRequest);
      setAccessToken(response.accessToken);
    }
  };

  return (
    <div>
      <h1>Access Token: {accessToken}</h1>
    </div>
  );
};

export default RefreshTokenScreen;
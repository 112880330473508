import React, { useState } from 'react'
import { useToken } from './auth'

export default () => {
  const [givenName, setGivenName] = useState('')
  const [accessToken, setAccessToken] = useState('')

  const [newRefreshToken, setNewRefreshToken] = useState('')


  const { id_token, access_token, refresh_token } = useToken()

  const handleSubmit = async (event) => {
    event.preventDefault()


    const params = new URLSearchParams({
      'given_name': givenName
    }).toString();

    // Call ADB2C API to update user profile with ccId and ebId
    const response = await fetch('https://nullmicgo.b2clogin.com/nullmicgo.onmicrosoft.com/B2C_1A_MFA_ISprint_signuporsignin/v2.0/me', {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${access_token}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: params
    })

    if (response.ok) {
      alert('User profile updated successfully!')
    } else {
      alert('Error updating user profile')
    }
  }

  const handleRefreshToken = async () => {
    // Call ADB2C API to refresh the access token using the refresh token

    const params = new URLSearchParams({
      refresh_token: refresh_token,
        grant_type:'refresh_token',
        client_id:'5014ed57-49c1-4b9e-acc9-a94f5b7e0c36',  
      scope: 'openid offline_access'
    }).toString();

    const response = await fetch('https://nullmicgo.b2clogin.com/nullmicgo.onmicrosoft.com/B2C_1A_MFA_ISprint_signuporsignin/oauth2/v2.0/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: params
    })

    if (response.ok) {
      const data = await response.json()

      // Store the new access token in localStorage and state
      localStorage.setItem('access_token', data.access_token)
     
      setNewRefreshToken(data.id_token)
      //old 
      //setAccessToken(data.access_token)

      alert('Access token refreshed successfully!')
    } else {
      alert('Error refreshing access token')
    }
  }

  return (
    <>
      <h3>Protected</h3>
      <p>Welcome back, you are logon</p>
      <p>id_token: {id_token}</p><br/>
      <p>Access token: {accessToken || access_token}</p>
      <br/><br/><br/><br/><br/><br/><br/><br/><br/>
      <form onSubmit={handleSubmit}>
        <label>
          Given Name:
          <input type="text" value={givenName} onChange={e => setGivenName(e.target.value)} />
        </label>
        <br />

        <br />
        <button type="submit">Update User Profile</button>
      </form>
      <br />
      <button onClick={handleRefreshToken}>Refresh Token</button>
      <p>Get New Access Token: {newRefreshToken}</p>


      
    </>
  )
}